import React, { Component, useCallback, useEffect, useState } from 'react'
import './Biometric/OnboardCard.sass'

import selfieIcon from './images/face-icon.svg'
import documentIcon from './images/card-icon.svg'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'

interface Props {
  onResponse: (response: any) => void
}

function Welcome({ onResponse }: Props) {
  const [photoState, setphotoState] = useState<string>('')

  const handleResponse = useCallback((message: string) => {
    setphotoState(message)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search) // id=123
    if (params.get('fixBrowser')) {
      setphotoState('mobile')
    }

    window.gtag('event', 'Onboarding: Welcome', {
      'event_category': 'onboarding',
      'event_label': 'welcome',
      'value': 1
    });
  }, [])

  return (
    <div className={`${photoState} card`}>
      <h1 className='text-center'>
      <span>Como</span> será?
      </h1>
      <hr />
      <p className="text-left">
      Iniciaremos a captura de sua face e, na sequência, faremos a captura também do seu documento, que deve ser válido e estar legível.
      </p>
      <ul className="big-icons">
        <li className='firstOne'>
          <img src={selfieIcon} />
        </li>
        <li>
          <img src={documentIcon} />
        </li>
      </ul>
      <div className="form-group form-submit">
        <a
          className="btn btn-primary form-control form-control-lg"
          onClick={() => onResponse({ step: 'faceinformation' })}
        >
          Avançar
        </a>
      </div>
    </div>
  )
}

export default Welcome
